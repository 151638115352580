import { SettingsCookie } from 'Shared/utils';

const threeHoursInSeconds = 60 * 60 * 3;

const lastViewedParameter = getLastViewedParameter();
const isValidParameter = lastViewedParameter && isValidLastPage(lastViewedParameter.href);

const onJoinOrLogin = new RegExp('(/login)|(/join)', 'i').test(window.location.href);
if ((!App.isLoggedIn && !onJoinOrLogin) || isValidParameter) {
   const lastPage = isValidParameter ? lastViewedParameter : new URL(window.location.href);
   lastPage.searchParams.delete('status');
   SettingsCookie.set('last_page', lastPage.href, threeHoursInSeconds);
}

function getLastViewedParameter() {
   const param = new URL(window.location.href).searchParams.get('last_page');
   if (!param) {
      return null;
   }
   try {
      return new URL(param);
   } catch {
      return new URL(param, window.location.origin);
   }
}

function isValidLastPage(url: string) {
   const notCurrentSite = !isCurrentSite(url);
   const notLocalhostInDevelopment = !url.includes('localhost') || App.isProduction;

   if (notCurrentSite && notLocalhostInDevelopment) {
      return false;
   }

   if (url.endsWith('Dev/blank')) {
      return false;
   }

   const invalidPages = [
      'place-order',
      'login',
      '/://0',
      'xml',
      'skin1.less',
      'logout',
      'blank.gif',
      'graph/time',
      'beta',
      '/favicon',
      '/api/',
      '/Guide/embed/',
      '.js',
   ];

   return !invalidPages.some(pageName => url.includes(pageName));
}

function isCurrentSite(url: string) {
   try {
      const parsedUrl = new URL(url);
      const onHost = parsedUrl.host === window.location.host;
      const onDomain = App.siteName === window.location.host.split('.').at(-2);
      return onHost || onDomain;
   } catch {
      // relative url
      return true;
   }
}
